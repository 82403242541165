import React, { useState, useRef, useEffect, useContext } from 'react';
import {
    Container,
    Typography,
    Button,
    IconButton,
    Stack,
    LinearProgress,
} from '@mui/material';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { StateContext } from './StateProvider';
import styles from './styles.module.scss';
import SaveTeamsButton from './SaveTeamsButton';
import { HentForrigeLagButton } from './HentForrigeLagButton';
import { TeamTables } from './TeamTables';
import { distributePlayers, konverterSpillerlisteTilJson, finnUgyldigeNavn } from './utils';
import { isEmpty } from 'lodash';

const LAG_FARGER = [
    {"tekst": "Blå", "color": "blue"},
    {"tekst": "Rød", "color": "red"},
    {"tekst": "Gul", "color": "yellow"},
    {"tekst": "Svart", "color": "black"}
];

const dndOptions = {
    // delay: 500,
    // delayTouchStart: 1000,
    // touchSlop: 100,
};

export const Content = () => {
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [teams, setTeams] = useState([]);
    const [inputJson, setInputJson] = useState(''); // Legg til denne tilstanden for å håndtere innsatt JSON
    const [feilliste, setFeilliste] = useState([]);
    const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
    const teamsContainerRef = useRef(null);
    const {
        nedreGrense2Lag,
    } = useContext(StateContext);

    useEffect(() => {
        if (teamsContainerRef.current && !hasScrolledIntoView) {
            teamsContainerRef.current.scrollIntoView({ behavior: 'smooth' });
            setHasScrolledIntoView(true);
        }
    }, [teams, hasScrolledIntoView]);

    useEffect(() => {
        const hasTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;
        setIsTouchDevice(hasTouch);
    }, []);

    const limInnOgFordel = () => {
        setIsLoading(true);
        if (isEmpty(inputJson)) {
            navigator.clipboard.readText()
                .then(clipboardContent => {
                    const withoutEmptyLines = clipboardContent.split('\n').filter(line => line.trim() !== "").join('\n');
                    setInputJson(withoutEmptyLines);
                    if (finnUgyldigeNavn(withoutEmptyLines).length < 1) {
                        const content = withoutEmptyLines.split('\n').filter(Boolean);
                        konverterSpillerlisteTilJson(content).then(playerlist => {
                            setIsLoading(false);
                            setFeilliste(playerlist.filter(player => !!player.error));
                            setSelectedPlayers(playerlist);
                            const teams = distributePlayers(playerlist, nedreGrense2Lag);
                            setTeams(teams);
                            // handleSetTeams(teams);
                        });
                    } else {
                        setIsLoading(false);
                        setFeilliste([{error: 'Utklippstavlen er ikke en spillerliste'}]);
                        alert('Utklippstavlen er ikke en spillerliste');
                    }
                })
                .catch(err => {
                    setIsLoading(false);
                    setFeilliste([{error: 'Du må legge til spillere i tekstfeltet'}]);
                    alert('Du må legge til spillere i tekstfeltet');
                });
        } else if (finnUgyldigeNavn(inputJson).length < 1) {
            const content = inputJson.split('\n').filter(line => line.trim() !== "").filter(Boolean);
            konverterSpillerlisteTilJson(content).then(playerlist => {
                setIsLoading(false);
                setFeilliste(playerlist.filter(player => !!player.error));
                setSelectedPlayers(playerlist);
                setTeams(distributePlayers(playerlist, nedreGrense2Lag));
            });
        } else {
            setIsLoading(false);
            setFeilliste([{error: 'Ugyldig spillerliste'}]);
            alert(`Ugyldig spillernavn:\n${finnUgyldigeNavn(inputJson).join('\n')}`);
        }
    };

    const resetSelection = () => {
        setSelectedPlayers([]);
        setTeams([]);
        setFeilliste([]);
        setInputJson('');
        setHasScrolledIntoView(false);
    };

    const handleCopyTables = () => {
        const tablesText = teams.map((team, index) => {
            const teamName = `Lag ${LAG_FARGER[index].tekst}`;
            const teamText = team.map(player => player.name).join('\n');
            return `${teamName}\n${teamText}`;
        }).join('\n\n');

        navigator.clipboard.writeText(tablesText)
            .then(() => {
                alert('Lagene er kopiert til utklippstavlen med dobbelt linjeskift mellom hver tabell!');
            })
            .catch(err => {
                console.error('Feil ved kopiering til utklippstavlen:', err);
                // Håndter feil, f.eks. gi brukeren en annen tilbakemelding
            });
    };
                  
    const handleSetTeams = (teams) => {
        setTeams(teams);
        const flattedData = teams.flat();
        setSelectedPlayers(flattedData);
        setInputJson(flattedData.map(player => player.name).join('\n'));
    };

    return (
        <Container maxWidth="sm" className={styles.container}>
            <Typography variant="h4" gutterBottom>
                Generer lag for Haslum fotball veteran
            </Typography>
            <textarea
                value={inputJson}
                onChange={(e) => setInputJson(e.target.value)}
                className={styles.textarea}
            />
            <Stack spacing={2} direction="row">
                <Button variant="contained" color="primary" onClick={limInnOgFordel}>
                    Fordel
                </Button>
                <Button variant="contained" color="secondary" onClick={resetSelection}>
                    Nullstill
                </Button>
                <HentForrigeLagButton onSetTeams={handleSetTeams} />
                <SaveTeamsButton teams={teams} />
            </Stack>

            {isLoading ? <div className={styles.spinnerWrapper}><LinearProgress /></div> :
                (
                    <div>
                        {feilliste.length > 0 &&
                            <div className={styles.error}>
                                <ul>
                                    {feilliste.map(({error}) => <li>{error}</li>)}
                                </ul>
                            </div>
                        }
                        {teams.length > 0 && (
                            <div ref={teamsContainerRef}>
                                <div className={styles.headerWithIcon}>
                                    <h2>{`Genererte Lag (${selectedPlayers.length} spillere)`}</h2>
                                    <IconButton onClick={handleCopyTables} aria-label="Kopier lagene til utklippstavle">
                                        <ContentCopyRoundedIcon fontSize='small' />
                                    </IconButton>
                                </div>

                                <DndProvider backend={isTouchDevice ? TouchBackend : HTML5Backend} options={dndOptions}>
                                    <TeamTables allTeams={teams} teams={teams} setTeams={setTeams} updateTeams={handleSetTeams} />
                                </DndProvider>
                            </div>
                        )}
                    </div>
                )
            }
        </Container>
    );
};