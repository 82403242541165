import React, { useContext, useEffect, useState } from 'react';
import {
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { StateContext } from './StateProvider';
import styles from './styles.module.scss';
import classNames from 'classnames';

export const Menu = () => {
    const [visStickyMenuBackground, setVisStickyMenuBackground] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const {
        maksAntallSpillere,
        setMaksAntallSpillere,
        
        visPosisjon,
        setVisPosisjon,
        visGruppe,
        setVisGruppe,

        nedreGrense2Lag,
        setNedreGrense2Lag,
    } = useContext(StateContext);
    const [inputMaxPlayers, setInputMaxPlayers] = useState(maksAntallSpillere);
    const [inputLimitTeams, setInputLimitTeams] = useState(nedreGrense2Lag);
    const [isCheckedPosisjon, setIsCheckedPosisjon] = useState(visPosisjon);
    const [isCheckedGruppe, setIsCheckedGruppe] = useState(visGruppe);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setVisStickyMenuBackground(true);
            } else {
                setVisStickyMenuBackground(false);                
            }
        }

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  
    const handleDrawerOpen = () => {
      setIsDrawerOpen(true);
    };
  
    const handleDrawerClose = () => {
      setIsDrawerOpen(false);
    };
    
    const handleInputMaxPlayersChange = (event) => {
      setInputMaxPlayers(event.target.value);
      setMaksAntallSpillere(event.target.value);
    };
    
    const handleInputLimitTeamsChange = (event) => {
        setInputLimitTeams(event.target.value);
        setNedreGrense2Lag(event.target.value);
    };


    const handleCheckboxPosisjonChange = () => {
      setIsCheckedPosisjon(!isCheckedPosisjon);
      setVisPosisjon(!isCheckedPosisjon);
    };

    const handleCheckboxGruppeChange = () => {
        setIsCheckedGruppe(!isCheckedGruppe);
        setVisGruppe(!isCheckedGruppe);
      };

    return (
        <div className={classNames(
            styles.stickyMenuBackground,
            {
                [styles.stickyMenuBackgroundVisible]: visStickyMenuBackground
            }
        )}>
            <IconButton
                className={styles.menuButton}
                color="inherit"
                aria-label="open menu"
                edge="start"
                onClick={handleDrawerOpen}
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>
            <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={handleDrawerClose}
            >
                <List>
                    {/* Legg til innstillinger eller annet innhold i sidemenyen */}
                    <ListItem button onClick={handleDrawerClose}>
                        <ListItemText primary="Innstillinger" />
                    </ListItem>
                    <ListItem>
                        <TextField
                        label="Maks antall spillere"
                        value={inputMaxPlayers}
                        onChange={handleInputMaxPlayersChange}
                        className={styles.textField}
                        />
                    </ListItem>
                    <ListItem>
                        <TextField
                        label="Nedre grense for 2 lag"
                        value={inputLimitTeams}
                        onChange={handleInputLimitTeamsChange}
                        className={styles.textField}
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            control={
                                <Checkbox
                                checked={isCheckedPosisjon}
                                onChange={handleCheckboxPosisjonChange}
                                className={styles.checkbox}
                                />
                            }
                            label="Vis posisjon"
                        />
                    </ListItem>
                    <ListItem>
                        <FormControlLabel
                            control={
                                <Checkbox
                                checked={isCheckedGruppe}
                                onChange={handleCheckboxGruppeChange}
                                className={styles.checkbox}
                                />
                            }
                            label="Vis gruppe"
                        />
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
};