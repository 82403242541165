import get from 'lodash/get';
import { hentSpillerliste } from './hentSpillerliste';

let cachedObjectList = null;
let cachedNameList = null;
const playerNameRegex = /^[a-zA-ZæøåÆØÅüÜ\s-.]+$/;

export function distributePlayers(players, nedreGrense2Lag) {
  const shuffledPlayers = [...players].sort(() => Math.random() - 0.5);
  const numberOfTeams = players.length <= nedreGrense2Lag ? 2 : 4;

  const teams = Array.from({ length: numberOfTeams }, () => []);

  let playersByAgePositionLevel = {};

  shuffledPlayers.forEach(player => {
    playersByAgePositionLevel = {
      ...playersByAgePositionLevel,
      [player.position]: {
        ...playersByAgePositionLevel[player.position],
        [player.group]: {
          ...get(playersByAgePositionLevel, [player.position, player.group], {}),
          [player.level || 'default']: [
            ...get(playersByAgePositionLevel, [player.position, player.group, player.level || 'default'], []),
            player,
          ],
        },
      }
    };
  });

  let teamIndex = 0;
  Object.values(playersByAgePositionLevel).forEach(groups => {
    Object.values(groups).forEach(levels => {
      Object.values(levels).forEach(players => {
        players.forEach(player => {
          teams[teamIndex++].push(player);
          if (teamIndex >= teams.length) {
            teamIndex = 0;
          }
        });
      });
    });
  });

  return teams;
}

export const finnUgyldigeNavn = (text) => {
  // Del teksten basert på linjeskift
  const lines = text.split(/\r?\n/).filter(line => line.trim() !== "");

  return lines.filter(line => !playerNameRegex.test(line.trim()));
};

export const konverterSpillerlisteTilJson = async (liste) => {
  try {
    if (!cachedNameList || !liste.every(name => cachedNameList.includes(name))) {
      cachedNameList = liste;
      cachedObjectList = await hentSpillerliste();
    }

    // Mapper navnene til objekter ved å sammenligne med objektlisten
    const mappedObjects = liste.map((name) => {
      const searchWords = name.trim().toLowerCase().split(' ');

      const exactMatch = cachedObjectList.find(obj =>
        searchWords.every(word => obj.name.toLowerCase().includes(word))
      );

      return exactMatch
        ? exactMatch
        : { name, error: `Ingen match på "${name}"` };
    });

    return mappedObjects;
  } catch (error) {
    console.error("Feil ved henting av objektliste:", error);
    return [];
  }
};
