import React, { useState } from 'react';
import { saveData } from './dbhelper';
import { Button, Modal, Box, Typography } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SaveTeamsButton = ({ teams }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [teamName, setTeamName] = useState('');

  const handleSaveClick = async () => {
    setDialogOpen(true);
  };

  const handleSaveConfirm = async () => {
    if (teamName.trim() !== '') {
      try {
        await saveData({ teams, teamName });
        setDialogOpen(false);
        setTeamName('');
      } catch (error) {
        console.error('Error saving data:', error);
      }
    }
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setTeamName('');
  };

  return (
    <div>
      <Button variant="contained" color="primary"  onClick={handleSaveClick}>
        Lagre    
      </Button>

      {dialogOpen && (
        <Modal
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
         <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Lagre lagene
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <input
                type="text"
                placeholder="Skriv inn lagets navn"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
              />
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" onClick={handleSaveConfirm}>Lagre</Button>
              <Button variant="contained" color="secondary" onClick={handleDialogClose}>Avbryt</Button>
            </Typography>
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default SaveTeamsButton;
