const DB_NAME = 'myDatabase';
const DB_VERSION = 1;
const STORE_NAME = 'players';

export const openDatabase = () => {
  return new Promise((resolve, reject) => {
    const request = window.indexedDB.open(DB_NAME, DB_VERSION);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME, { keyPath: 'id', autoIncrement: true });
      }
    };

    request.onsuccess = () => resolve(request.result);
    request.onerror = (event) => reject(event.error);
  });
};

export const saveData = (data) => {
  return new Promise(async (resolve, reject) => {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_NAME, 'readwrite');
    const store = transaction.objectStore(STORE_NAME);

    const request = store.add({ data });

    request.onsuccess = () => resolve();
    request.onerror = (event) => reject(event.error);
  });
};

export const getData = () => {
  return new Promise(async (resolve, reject) => {
    const db = await openDatabase();
    const transaction = db.transaction(STORE_NAME, 'readonly');
    const store = transaction.objectStore(STORE_NAME);

    const request = store.get(13); // Just an example key, modify as needed

    request.onsuccess = () => resolve(request.result ? request.result.data : null);
    request.onerror = (event) => reject(event.error);
  });
};

export const getAllData = () => {
    return new Promise(async (resolve, reject) => {
      const db = await openDatabase();
      const transaction = db.transaction(STORE_NAME, 'readonly');
      const store = transaction.objectStore(STORE_NAME);
      const data = [];
  
      const request = store.openCursor();
  
      request.onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          data.push(cursor.value.data);
          cursor.continue();
        } else {
          resolve(data);
        }
      };
  
      request.onerror = (event) => reject(event.error);
    });
  };