import React, { createContext, useState } from 'react';

const MAKS_ANTALL_SPILLERE = 30;
const NEDRE_GRENSE_2_LAG = 20;

export const StateProvider = ({ children }) => {
    const [maksAntallSpillere, setMaksAntallSpillere] = useState(MAKS_ANTALL_SPILLERE);
    const [nedreGrense2Lag, setNedreGrense2Lag] = useState(NEDRE_GRENSE_2_LAG);
    const [visPosisjon, setVisPosisjon] = useState(false);
    const [visGruppe, setVisGruppe] = useState(false);
    const theState = {
        maksAntallSpillere,
        setMaksAntallSpillere,
        visPosisjon,
        setVisPosisjon,
        visGruppe,
        setVisGruppe,
        nedreGrense2Lag,
        setNedreGrense2Lag,
    };

    return (
        <StateContext.Provider value={theState}>
            {children}
        </StateContext.Provider>
    );
};

export const StateContext = createContext({});