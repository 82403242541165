import React from 'react';
import { Menu } from './Menu';
import { Content } from './Content';
import { StateProvider } from './StateProvider';

function App() {
  return (
    <StateProvider>
      <Menu />
      <Content />
    </StateProvider>
    
  );
}

export default App;
