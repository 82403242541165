import React, { useState } from "react";
import { getAllData } from "./dbhelper";
import { Button, Box, List, ListItem, ListItemButton, Modal, Typography } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  maxHeight: '70%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const HentForrigeLagButton = ({ onSetTeams }) => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [data, setData] = useState([]);

    const handleHentAlleLagClick = async () => {
        try {
            const allData = await getAllData();
            const tmp = allData
                .filter(d => d.teamName)
                .filter((value, index, array) => {
                    return array.findLastIndex(p => p.teamName === value.teamName) === index;
                });
            setData(tmp);
            setDialogOpen(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleVelgLagClick = (lag) => {
        onSetTeams(lag);
        setDialogOpen(false);
    };

    const handleDialogClose = () => {
      setDialogOpen(false);
    };

    return (
        <div>
            <Button variant="contained" color="primary"  onClick={handleHentAlleLagClick}>
                Hent
            </Button>

            {dialogOpen && (
            <Modal
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Velg lag
                    </Typography>
                    <List>
                        {data.map(v => (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handleVelgLagClick(v.teams)}>
                                    {v.teamName}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Modal>
            )}
        </div>
    );
};