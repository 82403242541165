import axios from 'axios';

const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSKz8u9947GC1_lWBS-WtogsFf7KGuI-8O6rMAQaji4mU_rhqUsznNuFstoekaukZLsxLZLdofpUwPw/pub?gid=0&single=true&output=csv';

export const hentSpillerliste = async () =>  {
    try {
        const response = await axios.get(csvUrl);
        const parsedCsvData = parseCSV(response.data);
        return parsedCsvData;
    } catch (error) {
        console.error('Error fetching CSV data:', error);
        throw error; // Kast feilen videre for håndtering utenfor denne funksjonen
    }
};

function parseCSV(csvText) {
    const rows = csvText.split(/\r?\n/);        // Use a regular expression to split the CSV text into rows while handling '\r'
    const headers = rows[0].split(',');        // Extract headers (assumes the first row is the header row)
    const data = [];        // Initialize an array to store the parsed data
    for (let i = 1; i < rows.length; i++) {
        const rowData = rows[i].split(',');          // Use the regular expression to split the row while handling '\r'
        const rowObject = {};
        for (let j = 0; j < headers.length; j++) {
            rowObject.id = i;
            rowObject[headers[j].toLowerCase()] = rowData[j];
        }
        data.push(rowObject);
    }
    return data;
}